import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      © 2024 Guidant. All rights reserved.
    </footer>
  );
}

export default Footer;
