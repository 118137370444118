import React, { useMemo, useState, useEffect } from 'react';
import EmptyState from './EmptyState';
import editIcon from './../../assets/edit-icon.png';
import trashIcon from './../../assets/trash-icon.png';
import saveIcon from './../../assets/blue-save-icon.png';
import resolveIcon from './../../assets/resolve-icon.png';
import greenDot from './../../assets/green-dot.png';
import greyDot from './../../assets/grey-dot.png';
import importDataIcon from './../../assets/import-data-icon.png';
import plusIcon from './../../assets/plus-icon.png';
import paginationLeftIcon from './../../assets/pagination-arrow-left.png';
import paginationRightIcon from './../../assets/pagination-arrow-right.png';
import warningIconCircle from './../../assets/warning-icon-circle.png';
import InputMask from 'react-input-mask';
import blueDot from './../../assets/blue-dot.png';
import notedBadge from './../../assets/noted-badge.png';
import DataSourceDisplay from './DataSourceDisplay';
import HoursWorkedTooltip from './HoursWorkedTooltip';
import Cleave from 'cleave.js/react';


import './ResultsTable.css';

const ResultsTable = ({
  data,
  currentPage,
  rowsPerPage,
  isAddingNewRow,
  editableRowIndex,
  editableRowData,
  handlePageChange,
  handleEditRow,
  handleSaveRow,
  handleInputChange,
  handleResolveClick,
  handleBulkResolveClick,
  handleZeroHourClick,
  setIsAddingNewRow,
  setEditableRowIndex,
  initialEditableRowData,
  handleAddRow,
  setEditableRowData,
  setCurrentView,
  isOldData,
  alertFilters,
  handleDeleteRowClick,
  conflictColumns
}) => {
  const [dataSourceDisplay, setDataSourceDisplay] = useState({ source: '', anchorEl: null });
  const [hoursWorkedTooltip, setHoursWorkedTooltip] = useState({ show: false, position: { x: 0, y: 0 } });
  const [tooltip, setTooltip] = useState({ show: false, source: '', position: null });

  const rows = Object.entries(data.Row || {});
  const getFieldValue = (field, isNameField = false) => {
    return field?.FinalValue?.Value || (isNameField ? '' : '-');
  };  

  const sortedRows = useMemo(() => {
    return rows.sort(([, employeeA], [, employeeB]) => {
      const fullNameA = `${getFieldValue(employeeA.FirstName, true)} ${getFieldValue(employeeA.MiddleName, true)} ${getFieldValue(employeeA.LastName, true)}`.trim().toLowerCase();
      const fullNameB = `${getFieldValue(employeeB.FirstName, true)} ${getFieldValue(employeeB.MiddleName, true)} ${getFieldValue(employeeB.LastName, true)}`.trim().toLowerCase();
      return fullNameA.localeCompare(fullNameB);
    });
  }, [rows]);

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .table-container {
        -webkit-overflow-scrolling: touch;
        overflow-y: hidden;
        overflow-x: scroll;
      }
    `;
    document.head.appendChild(style);

    // Ensure the scrollbars are always visible
    const tableContainer = document.querySelector('.table-container');
    tableContainer.style.overflowX = 'scroll';
    tableContainer.style.overflowY = 'hidden';

    // Cleanup the added style when component unmounts
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    const handleWindowClick = () => {
      setTooltip({ show: false, source: '', position: null });
    };
  
    window.addEventListener('click', handleWindowClick);
  
    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, []);
  

  const handleCellMouseEnter = (event, source) => {
    const rect = event.target.getBoundingClientRect();
    setTooltip({
      show: true,
      source,
      position: { x: rect.left, y: rect.bottom }
    });
  };

  const handleCellMouseLeave = () => {
    setTooltip({ show: false, source: '', position: null });
  };
  
  const formatCurrency = (value) => {
    const numberValue = parseFloat(value);
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(numberValue);
  };


  const totalPages = Math.ceil(sortedRows.length / rowsPerPage);
  const startRow = (currentPage - 1) * rowsPerPage;
  const endRow = startRow + rowsPerPage;
  const currentRows = sortedRows.slice(startRow, endRow);

  const showEmptyState = !isAddingNewRow && (rows.length === 0);

  const getCellColorAndMessage = (status, alertFilters, message) => {
    const colorClasses = {
      'Error': 'error-cell',
      'Warning': 'warning-cell',
      'Invalid' : 'invalid-cell'
    };
  
    const textColorClasses = {
      'Error': 'error-message',
      'Warning': 'warning-message',
      'Invalid' : 'invalid-message'
    };
  
    let colorClass = '';
    let textColorClass = '';
    let cellMessage = message || '';
  
    // Always color the text based on status
    if (status === 'Error') {
      textColorClass = textColorClasses['Error'];
    } else if (status === 'Warning') {
      textColorClass = textColorClasses['Warning'];
    } else if (status === 'Invalid'){
      textColorClass = textColorClasses['Invalid'];
    }
  
    // Only apply background color when alert filters are active
    if (alertFilters.includes('Error') && status === 'Error') {
      colorClass = colorClasses['Error'];
    } else if (alertFilters.includes('Warning') && status === 'Warning') {
      colorClass = colorClasses['Warning'];
    }else if (alertFilters.includes('Invalid') && status === 'Invalid'){
      colorClass = colorClasses['Invalid'];
    }
  
    return { colorClass, textColorClass, message: cellMessage };
  };
  

  const renderTableHeader = () => {
    const headers = [
      { name: 'NAME *', field: 'FullName' },
      { name: 'SSN *', field: 'SSN' },
      { name: 'DATE OF BIRTH *', field: 'DateOfBirth' },
      { name: 'HIRE DATE *', field: 'DateOfHire' },
      { name: 'STATUS *', field: 'Status' },
      { name: 'TERMINATION DATE', field: 'DateOfTermination' },
      { name: 'GROSS COMPENSATION *', field: 'GrossCompensation' },
      { name: 'HOURS WORKED *', field: 'HoursWorked' },
      { name: 'PRE-TAX CONTRIBUTIONS', field: 'DeferALS' },
      { name: 'ROTH CONTRIBUTIONS', field: 'ParticipantRoth' },
      { name: 'EMPLOYER MATCH', field: 'CompanyMatch' },
      { name: 'PROFIT SHARING', field: 'CompanyProfitSharing' },
      { name: 'ROLLOVER', field: 'RollOver' },
      { name: 'LOAN PAYMENT', field: 'AnnualLoanPayment' },
    ];

    return (
      <thead>
        <tr>
          {headers.map(header => (
            <th key={header.field}>
              {header.name}
              {conflictColumns.includes(header.field) && (
                <button className="resolve-button-header" onClick={() => handleBulkResolveClick(header.field)}>
                  RESOLVE ALL <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
                </button>
              )}
            </th>
          ))}
          <th className="sticky-column">
            <div className="shadow-overlay"></div>
          </th>

        </tr>
      </thead>
    );
  };

  const renderInputRow = () => {
    // Get original data at start of function
    const originalData = data.Row[editableRowData.originalKey];
   
    return (
      <tr 
        className="editable-row" 
        key="editable-row"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSaveRow();
          }
        }}
      >
      <td>
        {(originalData?.FirstName?.FinalValue?.Value === 'conflict' || originalData?.LastName?.FinalValue?.Value === 'conflict') ? (
          <button className="resolve-button" onClick={() => handleResolveClick([...originalData.FirstName.Values, ...originalData.LastName.Values], editableRowData.originalKey, 'FullName', isOldData)}>
            RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
          </button>
        ) : (
          <div className="input-container">
            <input
              type="text"
              className={`editable-input ${
                (originalData?.FirstName?.FinalValue?.Status === 'Error' || 
                originalData?.FirstName?.FinalValue?.Status === 'Invalid' ||
                originalData?.LastName?.FinalValue?.Status === 'Error' ||
                originalData?.LastName?.FinalValue?.Status === 'Invalid') 
                ? 'error-input' 
                : ''
              }`}
              placeholder="ex: John Doe"
              value={editableRowData.FullName || ''}
              onChange={(e) => handleInputChange('FullName', e.target.value)}
            />
            {(originalData?.FirstName?.FinalValue?.Status === 'Error' || 
              originalData?.FirstName?.FinalValue?.Status === 'Invalid') && (
              <div className="error-message-input">
                {originalData.FirstName.FinalValue.Message}
              </div>
            )}
            {(originalData?.LastName?.FinalValue?.Status === 'Error' || 
              originalData?.LastName?.FinalValue?.Status === 'Invalid') && (
              <div className="error-message-input">
                {originalData.LastName.FinalValue.Message}
              </div>
            )}
          </div>
        )}
      </td>
      <td>
        {originalData?.SSN?.FinalValue?.Value === 'conflict' ? (
          <button className="resolve-button" onClick={() => handleResolveClick(originalData.SSN.Values, editableRowData.originalKey, 'SSN', isOldData)}>
            RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
          </button>
        ) : (
          <div className="input-container">
            <Cleave
              className={`editable-input ${editableRowData.PriorYearEmployee === "true" ? 'disabled-input' : ''} ${
                (originalData?.SSN?.FinalValue?.Status === 'Error' || 
                originalData?.SSN?.FinalValue?.Status === 'Invalid') 
                ? 'error-input' 
                : ''
              }`}
              placeholder="XXX-XX-XXXX"
              options={{
                delimiter: '-',
                blocks: [3, 2, 4],
                numericOnly: true
              }}
              value={editableRowData.SSN || ''}
              onChange={(e) => handleInputChange('SSN', e.target.value)}
              readOnly={editableRowData.PriorYearEmployee === "true"}
            />
            {(originalData?.SSN?.FinalValue?.Status === 'Error' || 
              originalData?.SSN?.FinalValue?.Status === 'Invalid') && (
              <div className="error-message-input">
                {originalData.SSN.FinalValue.Message}
              </div>
            )}
          </div>
        )}
      </td>
      <td>
  {originalData?.DateOfBirth?.FinalValue?.Value === 'conflict' ? (
    <button className="resolve-button" onClick={() => handleResolveClick(originalData.DateOfBirth.Values, editableRowData.originalKey, 'DateOfBirth', isOldData)}>
      RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
    </button>
  ) : (
    <div className="input-container">
      <Cleave
        className={`editable-input ${
          (originalData?.DateOfBirth?.FinalValue?.Status === 'Error' || 
          originalData?.DateOfBirth?.FinalValue?.Status === 'Invalid') 
          ? 'error-input' 
          : ''
        }`}
        placeholder="mm/dd/yyyy"
        options={{
          date: true,
          datePattern: ['m', 'd', 'Y'],
          delimiter: '/'
        }}
        value={editableRowData.DateOfBirth ? formatDateForInput(editableRowData.DateOfBirth) : ''}
        onChange={(e) => handleInputChange('DateOfBirth', e.target.value)}
      />
      {(originalData?.DateOfBirth?.FinalValue?.Status === 'Error' || 
        originalData?.DateOfBirth?.FinalValue?.Status === 'Invalid') && (
        <div className="error-message-input">
          {originalData.DateOfBirth.FinalValue.Message}
        </div>
      )}
    </div>
  )}
</td>
<td>
  {originalData?.DateOfHire?.FinalValue?.Value === 'conflict' ? (
    <button className="resolve-button" onClick={() => handleResolveClick(originalData.DateOfHire.Values, editableRowData.originalKey, 'DateOfHire', isOldData)}>
      RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
    </button>
  ) : originalData?.DateOfHire?.FinalValue?.Value?.toLowerCase() === 'rehire' ? (
    <button className="resolve-button" onClick={() => handleResolveClick(originalData.DateOfHire.Values, editableRowData.originalKey, 'rehire', isOldData)}>
      RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
    </button>
  ) : (
    <div className="input-container">
      <Cleave
        className={`editable-input ${
          (originalData?.DateOfHire?.FinalValue?.Status === 'Error' || 
          originalData?.DateOfHire?.FinalValue?.Status === 'Invalid') 
          ? 'error-input' 
          : ''
        }`}
        placeholder="mm/dd/yyyy"
        options={{
          date: true,
          datePattern: ['m', 'd', 'Y'],
          delimiter: '/'
        }}
        value={editableRowData.DateOfHire ? formatDateForInput(editableRowData.DateOfHire) : ''}
        onChange={(e) => handleInputChange('DateOfHire', e.target.value)}
      />
      {(originalData?.DateOfHire?.FinalValue?.Status === 'Error' || 
        originalData?.DateOfHire?.FinalValue?.Status === 'Invalid') && (
        <div className="error-message-input">
          {originalData.DateOfHire.FinalValue.Message}
        </div>
      )}
    </div>
  )}
</td>
<td>
  {originalData?.Status?.FinalValue?.Value === 'conflict' ? (
    <button className="resolve-button" onClick={() => handleResolveClick(originalData.Status.Values, editableRowData.originalKey, 'Status', isOldData)}>
      RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
    </button>
  ) : (
    <div className="input-container">
      <select
        className={`editable-input editable-select ${
          (originalData?.Status?.FinalValue?.Status === 'Error' || 
          originalData?.Status?.FinalValue?.Status === 'Invalid') 
          ? 'error-input' 
          : ''
        }`}
        value={editableRowData.Status || ''}
        onChange={(e) => handleInputChange('Status', e.target.value)}
      >
        <option value="Active">Active</option>
        <option value="Termination">Terminated</option>
      </select>
      {(originalData?.Status?.FinalValue?.Status === 'Error' || 
        originalData?.Status?.FinalValue?.Status === 'Invalid') && (
        <div className="error-message-input">
          {originalData.Status.FinalValue.Message}
        </div>
      )}
    </div>
  )}
</td>
<td>
  {originalData?.DateOfTermination?.FinalValue?.Value === 'conflict' ? (
    <button className="resolve-button" onClick={() => handleResolveClick(originalData.DateOfTermination.Values, editableRowData.originalKey, 'DateOfTermination', isOldData)}>
      RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
    </button>
  ) : (
    <div className="input-container">
      <Cleave
        className={`editable-input ${
          (originalData?.DateOfTermination?.FinalValue?.Status === 'Error' || 
          originalData?.DateOfTermination?.FinalValue?.Status === 'Invalid') 
          ? 'error-input' 
          : ''
        }`}
        placeholder="mm/dd/yyyy"
        options={{
          date: true,
          datePattern: ['m', 'd', 'Y'],
          delimiter: '/'
        }}
        value={editableRowData.DateOfTermination ? formatDateForInput(editableRowData.DateOfTermination) : ''}
        onChange={(e) => handleInputChange('DateOfTermination', e.target.value)}
      />
      {(originalData?.DateOfTermination?.FinalValue?.Status === 'Error' || 
        originalData?.DateOfTermination?.FinalValue?.Status === 'Invalid') && (
        <div className="error-message-input">
          {originalData.DateOfTermination.FinalValue.Message}
        </div>
      )}
    </div>
  )}
</td>
        <td>
          {originalData?.GrossCompensation?.FinalValue?.Value === 'conflict' ? (
            <button className="resolve-button">...</button>
          ) : (
            <div className="input-container">
              <input
                type="number"
                className={`editable-input ${
                  (originalData?.GrossCompensation?.FinalValue?.Status === 'Error' || 
                  originalData?.GrossCompensation?.FinalValue?.Status === 'Invalid') 
                  ? 'error-input' 
                  : ''
                }`}
                placeholder="ex: 3,000"
                value={editableRowData.GrossCompensation || ''}
                onChange={(e) => handleInputChange('GrossCompensation', e.target.value)}
              />
              {(originalData?.GrossCompensation?.FinalValue?.Status === 'Error' || 
                originalData?.GrossCompensation?.FinalValue?.Status === 'Invalid') && (
                <div className="error-message-input">
                  {originalData.GrossCompensation.FinalValue.Message}
                </div>
              )}
            </div>
          )}
        </td>
        <td>
  {originalData?.HoursWorked?.FinalValue?.Value === 'conflict' ? (
    <button className="resolve-button" onClick={() => handleResolveClick(originalData.HoursWorked.Values, editableRowData.originalKey, 'HoursWorked', isOldData)}>
      RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
    </button>
  ) : (
    <div className="input-container">
      <input
        type="number"
        className={`editable-input ${
          (originalData?.HoursWorked?.FinalValue?.Status === 'Error' || 
          originalData?.HoursWorked?.FinalValue?.Status === 'Invalid') 
          ? 'error-input' 
          : ''
        }`}
        placeholder="ex: 10,400"
        value={editableRowData.HoursWorked || ''}
        onChange={(e) => handleInputChange('HoursWorked', e.target.value)}
      />
      {(originalData?.HoursWorked?.FinalValue?.Status === 'Error' || 
        originalData?.HoursWorked?.FinalValue?.Status === 'Invalid') && (
        <div className="error-message-input">
          {originalData.HoursWorked.FinalValue.Message}
        </div>
      )}
    </div>
  )}
</td>
<td>
  {originalData?.DeferALS?.FinalValue?.Value === 'conflict' ? (
    <button className="resolve-button" onClick={() => handleResolveClick(originalData.DeferALS.Values, editableRowData.originalKey, 'DeferALS', isOldData)}>
      RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
    </button>
  ) : (
    <div className="input-container">
      <input
        type="number"
        className={`editable-input ${
          (originalData?.DeferALS?.FinalValue?.Status === 'Error' || 
          originalData?.DeferALS?.FinalValue?.Status === 'Invalid') 
          ? 'error-input' 
          : ''
        }`}
        placeholder="ex: 1,000"
        value={editableRowData.DeferALS || ''}
        onChange={(e) => handleInputChange('DeferALS', e.target.value)}
      />
      {(originalData?.DeferALS?.FinalValue?.Status === 'Error' || 
        originalData?.DeferALS?.FinalValue?.Status === 'Invalid') && (
        <div className="error-message-input">
          {originalData.DeferALS.FinalValue.Message}
        </div>
      )}
    </div>
  )}
</td>

<td>
  {originalData?.ParticipantRoth?.FinalValue?.Value === 'conflict' ? (
    <button className="resolve-button" onClick={() => handleResolveClick(originalData.ParticipantRoth.Values, editableRowData.originalKey, 'ParticipantRoth', isOldData)}>
      RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
    </button>
  ) : (
    <div className="input-container">
      <input
        type="number"
        className={`editable-input ${
          (originalData?.ParticipantRoth?.FinalValue?.Status === 'Error' || 
          originalData?.ParticipantRoth?.FinalValue?.Status === 'Invalid') 
          ? 'error-input' 
          : ''
        }`}
        placeholder="ex: 500"
        value={editableRowData.ParticipantRoth || ''}
        onChange={(e) => handleInputChange('ParticipantRoth', e.target.value)}
      />
      {(originalData?.ParticipantRoth?.FinalValue?.Status === 'Error' || 
        originalData?.ParticipantRoth?.FinalValue?.Status === 'Invalid') && (
        <div className="error-message-input">
          {originalData.ParticipantRoth.FinalValue.Message}
        </div>
      )}
    </div>
  )}
</td>

<td>
  {originalData?.CompanyMatch?.FinalValue?.Value === 'conflict' ? (
    <button className="resolve-button" onClick={() => handleResolveClick(originalData.CompanyMatch.Values, editableRowData.originalKey, 'CompanyMatch', isOldData)}>
      RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
    </button>
  ) : (
    <div className="input-container">
      <input
        type="number"
        className={`editable-input ${
          (originalData?.CompanyMatch?.FinalValue?.Status === 'Error' || 
          originalData?.CompanyMatch?.FinalValue?.Status === 'Invalid') 
          ? 'error-input' 
          : ''
        }`}
        placeholder="ex: 200"
        value={editableRowData.CompanyMatch || ''}
        onChange={(e) => handleInputChange('CompanyMatch', e.target.value)}
      />
      {(originalData?.CompanyMatch?.FinalValue?.Status === 'Error' || 
        originalData?.CompanyMatch?.FinalValue?.Status === 'Invalid') && (
        <div className="error-message-input">
          {originalData.CompanyMatch.FinalValue.Message}
        </div>
      )}
    </div>
  )}
</td>

<td>
  {originalData?.CompanyProfitSharing?.FinalValue?.Value === 'conflict' ? (
    <button className="resolve-button" onClick={() => handleResolveClick(originalData.CompanyProfitSharing.Values, editableRowData.originalKey, 'CompanyProfitSharing', isOldData)}>
      RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
    </button>
  ) : (
    <div className="input-container">
      <input
        type="number"
        className={`editable-input ${
          (originalData?.CompanyProfitSharing?.FinalValue?.Status === 'Error' || 
          originalData?.CompanyProfitSharing?.FinalValue?.Status === 'Invalid') 
          ? 'error-input' 
          : ''
        }`}
        placeholder="ex: 300"
        value={editableRowData.CompanyProfitSharing || ''}
        onChange={(e) => handleInputChange('CompanyProfitSharing', e.target.value)}
      />
      {(originalData?.CompanyProfitSharing?.FinalValue?.Status === 'Error' || 
        originalData?.CompanyProfitSharing?.FinalValue?.Status === 'Invalid') && (
        <div className="error-message-input">
          {originalData.CompanyProfitSharing.FinalValue.Message}
        </div>
      )}
    </div>
  )}
</td>

<td>
  {originalData?.RollOver?.FinalValue?.Value === 'conflict' ? (
    <button className="resolve-button" onClick={() => handleResolveClick(originalData.RollOver.Values, editableRowData.originalKey, 'RollOver', isOldData)}>
      RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
    </button>
  ) : (
    <div className="input-container">
      <input
        type="number"
        className={`editable-input ${
          (originalData?.RollOver?.FinalValue?.Status === 'Error' || 
          originalData?.RollOver?.FinalValue?.Status === 'Invalid') 
          ? 'error-input' 
          : ''
        }`}
        placeholder="ex: 100"
        value={editableRowData.RollOver || ''}
        onChange={(e) => handleInputChange('RollOver', e.target.value)}
      />
      {(originalData?.RollOver?.FinalValue?.Status === 'Error' || 
        originalData?.RollOver?.FinalValue?.Status === 'Invalid') && (
        <div className="error-message-input">
          {originalData.RollOver.FinalValue.Message}
        </div>
      )}
    </div>
  )}
</td>

<td>
  {originalData?.AnnualLoanPayment?.FinalValue?.Value === 'conflict' ? (
    <button className="resolve-button" onClick={() => handleResolveClick(originalData.AnnualLoanPayment.Values, editableRowData.originalKey, 'AnnualLoanPayment', isOldData)}>
      RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
    </button>
  ) : (
    <div className="input-container">
      <input
        type="number"
        className={`editable-input ${
          (originalData?.AnnualLoanPayment?.FinalValue?.Status === 'Error' || 
          originalData?.AnnualLoanPayment?.FinalValue?.Status === 'Invalid') 
          ? 'error-input' 
          : ''
        }`}
        placeholder="ex: 150"
        value={editableRowData.AnnualLoanPayment || ''}
        onChange={(e) => handleInputChange('AnnualLoanPayment', e.target.value)}
      />
      {(originalData?.AnnualLoanPayment?.FinalValue?.Status === 'Error' || 
        originalData?.AnnualLoanPayment?.FinalValue?.Status === 'Invalid') && (
        <div className="error-message-input">
          {originalData.AnnualLoanPayment.FinalValue.Message}
        </div>
      )}
    </div>
  )}
</td>

<td className="editable-buttons sticky-column">
  <div className="shadow-overlay"></div>
  <div className="sticky-column-content">
    <div className="buttons-container">
      <button className="save-button-row-edit" onClick={handleSaveRow}>
        <img src={saveIcon} alt="Save" className="small-save-icon" />
        SAVE
      </button>
      <button className="cancel-button" onClick={() => { setIsAddingNewRow(false); setEditableRowIndex(null); setEditableRowData(initialEditableRowData); }}>
        X
      </button>
    </div>
  </div>
</td>
      </tr>
    );
   };
  
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const [month, day, year] = dateString.split('-');
    return `${month}/${day}/${year}`;
  };

  const renderFullName = (
    firstName,
    lastName,
    ownershipPercent,
    handleResolveClick,
    employeeKey,
    isOldData,
    alertFilters,
    handleCellMouseEnter,
    handleCellMouseLeave
  ) => {
    const fullName = `${getFieldValue(firstName, true)} ${getFieldValue(lastName, true)}`;
    const isOwner = parseFloat(ownershipPercent?.FinalValue?.Value || "0") > 0;
    const fullNameConflict = firstName?.FinalValue?.Value === 'conflict' || lastName?.FinalValue?.Value === 'conflict';
  
    // Prioritize 'Error' over 'Warning' for name statuses
    const status =
      firstName?.FinalValue?.Status === 'Error' || lastName?.FinalValue?.Status === 'Error'
        ? 'Error'
        : firstName?.FinalValue?.Status === 'Warning' || lastName?.FinalValue?.Status === 'Warning'
        ? 'Warning'
        : '';
  
    // Determine the message based on the status
    let message = '';
    if (firstName?.FinalValue?.Status === 'Error') {
      message = firstName?.FinalValue?.Message || '';
    } else if (lastName?.FinalValue?.Status === 'Error') {
      message = lastName?.FinalValue?.Message || '';
    } else if (firstName?.FinalValue?.Status === 'Warning') {
      message = firstName?.FinalValue?.Message || '';
    } else if (lastName?.FinalValue?.Status === 'Warning') {
      message = lastName?.FinalValue?.Message || '';
    }
  
    const { colorClass, textColorClass, message: cellMessage } = getCellColorAndMessage(
      status,
      alertFilters,
      message
    );
  
    const source = firstName?.FinalValue?.Source || lastName?.FinalValue?.Source || 'N/A';
  
    const handleNameMouseEnter = (e) => {
      handleCellMouseEnter(e, source);
    };
  
    return (
      <div className="full-name-wrapper">
        <div className={`full-name-container ${colorClass}`}>
          {fullNameConflict ? (
            <button 
              className="resolve-button" 
              onClick={() => handleResolveClick([...firstName.Values, ...lastName.Values], employeeKey, 'FullName', isOldData)}
            >
              RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
            </button>
          ) : (
            <>
              <span 
                className={`truncate-name ${isOwner ? 'with-owner-icon' : ''}`}
                onMouseEnter={handleNameMouseEnter}
                onMouseLeave={handleCellMouseLeave}
                style={{ display: 'inline-block' }}
              >
                {fullName}
              </span>
              {isOwner && (
                <span className="owner-pill">
                  <img src={blueDot} alt="Owner Blue Dot" className="owner-dot" />
                  <span className="owner-text">Owner</span>
                </span>
              )}
            </>
          )}
        </div>
        {(status === 'Error' || status === 'Warning' || status === 'Invalid') && cellMessage && (
          <div className={`cell-message ${textColorClass}`}>{cellMessage}</div>
        )}
      </div>
    );
  };
  
  const renderCell = (field, fieldName, handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters) => {
    const value = field?.FinalValue?.Value;
    const status = field?.FinalValue?.Status;
    const message = field?.FinalValue?.Message;
    
    const { colorClass, textColorClass, message: cellMessage } = getCellColorAndMessage(status, alertFilters, message);
  
    const valueProps = {
      onMouseEnter: (e) => handleCellMouseEnter(e, field?.FinalValue?.Source || 'N/A'),
      onMouseLeave: handleCellMouseLeave,
    };
  
    // Base click handler for editable cells
    const handleCellClick = () => {
      if (value !== 'conflict') {
        handleEditRow(employeeKey);
      }
    };
  
    if (fieldName === 'Status') {
      const displayStatus = (value === 'Death' || value === 'Retirement' || value === 'Terminated' || value === 'Termination' || value === 'Inactive') ? 'Terminated' : 'Active';
      
      return (
        <td className={`status-cell ${colorClass} clickable-cell`} onClick={handleCellClick}>
          {value === 'conflict' ? (
            <button className="resolve-button" onClick={(e) => {
              e.stopPropagation();
              handleResolveClick(field.Values, employeeKey, 'Status', isOldData);
            }}>
              RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
            </button>
          ) : (
            <div className={`status-pill ${displayStatus === 'Terminated' ? 'terminated' : 'active'}`}>
              <img src={displayStatus === 'Terminated' ? greyDot : greenDot} alt="Status Dot" className="status-dot" />
              <span className="status-text" {...valueProps}>{displayStatus}</span>
            </div>
          )}
          {(status === 'Error' || status === 'Warning' || status === 'Invalid') && cellMessage && (
            <div className={`cell-message ${textColorClass}`}>{cellMessage}</div>
          )}
        </td>
      );
    }
  
    if (fieldName === 'DateOfHire') {
      const value = field?.FinalValue?.Value;
      if (value && value.toLowerCase() === 'rehire') {
        return (
          <td className={`${colorClass} clickable-cell`} onClick={handleCellClick}>
            <button className="resolve-button" onClick={(e) => {
              e.stopPropagation();
              handleResolveClick(field.Values, employeeKey, 'rehire', isOldData);
            }}>
              RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
            </button>
            {(status === 'Error' || status === 'Warning' || status === 'Invalid') && cellMessage && (
              <div className={`cell-message ${textColorClass}`}>{cellMessage}</div>
            )}
          </td>
        );
      }
    }
  
    if (value === 'conflict') {
      return (
        <td className={colorClass}>
          <button className="resolve-button" onClick={() => handleResolveClick(field.Values, employeeKey, fieldName, isOldData)}>
            RESOLVE <img src={resolveIcon} alt="Resolve Icon" className="resolve-icon" />
          </button>
          {(status === 'Error' || status === 'Warning' || status === 'Invalid') && cellMessage && (
            <div className={`cell-message ${textColorClass}`}>{cellMessage}</div>
          )}
        </td>
      );
    }
  
    if (fieldName === 'SSN') {
      if (!value || value.trim() === '') {
        return (
          <td className={`${colorClass} clickable-cell`} onClick={handleCellClick}>
            <span {...valueProps}>-</span>
            {(status === 'Error' || status === 'Warning' || status === 'Invalid') && cellMessage && (
              <div className={`cell-message ${textColorClass}`}>{cellMessage}</div>
            )}
          </td>
        );
      }
      const cleanedSSN = value.replace(/-/g, '');
      const maskedSSN = cleanedSSN.length === 9 ? `XXX-XX-${cleanedSSN.slice(-4)}` : cleanedSSN;
      return (
        <td className={`${colorClass} clickable-cell`} onClick={handleCellClick}>
          <span {...valueProps}>{maskedSSN}</span>
          {(status === 'Error' || status === 'Warning' || status === 'Invalid') && cellMessage && (
            <div className={`cell-message ${textColorClass}`}>{cellMessage}</div>
          )}
        </td>
      );
    }
  
    if (fieldName === 'HoursWorked') {
      let value = field?.FinalValue?.Value || '-';
      if (isNaN(parseFloat(value))) {
        value = '-';
      }
      const hoursFinalValue = field?.FinalValue?.Value;
      const grossCompensation = employee?.GrossCompensation.FinalValue?.Value;
      const hoursWorkedNoteFinalValue = employee?.HoursWorkedNote?.FinalValue?.Value;
      const ownershipPercent = parseFloat(employee?.OwnershipPercent?.FinalValue?.Value || '0');
      const isOwner = ownershipPercent > 0;
      
      return (
        <td 
          className={`${colorClass} zero-hour-hover`}
          onClick={() => handleZeroHourClick(employeeKey, isOldData, hoursFinalValue, grossCompensation, hoursWorkedNoteFinalValue, isOwner)}
        >
          <div className="hours-worked-container">
            <span {...valueProps}>{value}</span>
  
            {(() => {
              if (status === 'Warning') {
                const showTooltip = parseFloat(value) >= 1 && parseFloat(value) <= 499 && isOwner;
  
                if (showTooltip) {
                  return (
                    <img 
                      src={warningIconCircle} 
                      alt="Warning Icon" 
                      className="hours-info-icon" 
                      onMouseEnter={(e) => {
                        const rect = e.target.getBoundingClientRect();
                        setHoursWorkedTooltip({
                          show: true,
                          position: { x: rect.right + 10, y: rect.top }
                        });
                      }}
                      onMouseLeave={() => setHoursWorkedTooltip({ show: false, position: { x: 0, y: 0 } })}
                    />
                  );
                } else {
                  return (
                    <img src={warningIconCircle} alt="Warning Icon" className="hours-info-icon" />
                  );
                }
              }
            })()}
  
          </div>
          {hoursWorkedNoteFinalValue && hoursWorkedNoteFinalValue.trim() !== '' && (
            <img src={notedBadge} alt="Noted Badge" className="noted-badge-icon noted-badge-hover" />
          )}
          
          {(status === 'Error' || status === 'Warning' || status === 'Invalid') && cellMessage && (
            <div className={`cell-message ${textColorClass}`}>{cellMessage}</div>
          )}
        </td>
      );
    }
  
    const fieldsToNormalize = [
      'GrossCompensation',
      'HoursWorked',
      'DeferALS',
      'ParticipantRoth',
      'CompanyMatch',
      'CompanyProfitSharing',
      'AnnualLoanPayment',
      'RollOver'
    ];
  
    const isValueNumber = value && !isNaN(parseFloat(value)) && isFinite(value);
  
    return (
      <td className={`${colorClass} clickable-cell`} onClick={handleCellClick}>
        <span {...valueProps}>
          {value ? (fieldsToNormalize.includes(fieldName) && isValueNumber ? formatCurrency(value) : value) : '-'}
        </span>
        {(status === 'Error' || status === 'Warning' || status === 'Invalid') && cellMessage && (
          <div className={`cell-message ${textColorClass}`}>{cellMessage}</div>
        )}
      </td>
    );
  };
  
  const renderEmployeeRow = ([employeeKey, employee], index) => {
    if (editableRowIndex === employeeKey) {
      return renderInputRow(); 
    }
  
    const FullName = `${getFieldValue(employee.FirstName, true)} ${getFieldValue(employee.LastName, true)}`;
    const fullNameStatus = employee.FirstName?.FinalValue?.Status || employee.LastName?.FinalValue?.Status;
    
    // Get the colorClass for the entire cell
    const { colorClass, textColorClass, message: cellMessage } = getCellColorAndMessage(fullNameStatus, alertFilters, employee.FirstName?.FinalValue?.Message || employee.LastName?.FinalValue?.Message);
  
    return (
      <tr key={employeeKey}>
<td className={`name-column ${colorClass}`}>
    <div className="cell-content">
        {renderFullName(employee.FirstName, employee.LastName, employee.OwnershipPercent, handleResolveClick, employeeKey, isOldData, alertFilters, handleCellMouseEnter, handleCellMouseLeave)}
    </div>
    <div className="name-column-shadow"></div>
</td>
        {renderCell(employee.SSN, 'SSN', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.DateOfBirth, 'DateOfBirth', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.DateOfHire, 'DateOfHire', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.Status, 'Status', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.DateOfTermination, 'DateOfTermination', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.GrossCompensation, 'GrossCompensation', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.HoursWorked, 'HoursWorked', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.DeferALS, 'DeferALS', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.ParticipantRoth, 'ParticipantRoth', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.CompanyMatch, 'CompanyMatch', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.CompanyProfitSharing, 'CompanyProfitSharing', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.RollOver, 'RollOver', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        {renderCell(employee.AnnualLoanPayment, 'AnnualLoanPayment', handleResolveClick, handleZeroHourClick, employeeKey, FullName, isOldData, employee, alertFilters)}
        <td className="icons sticky-column">
  <div className="shadow-overlay"></div> {/* Continuous shadow overlay */}
  <div className="sticky-column-content">
    <img src={trashIcon} alt="Delete" className="icon delete-icon" onClick={() => handleDeleteRowClick(employeeKey, isOldData)} />
    <img src={editIcon} alt="Edit" className="icon edit-icon" onClick={() => handleEditRow(employeeKey)} />
  </div>
</td>



      </tr>
    );
  };
  
  
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
  
    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);
  
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push('...');
      }
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }
      pageNumbers.push(totalPages);
    }
  
    return pageNumbers.map((page, index) => (
      page === '...' ? (
        <span key={index} className="pagination-ellipsis">...</span>
      ) : (
        <button
          key={index}
          className={`pagination-button ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </button>
      )
    ));
  };

  
  

  return (
    <div className="results-section">
      {showEmptyState ? (
        <div className="empty-state-wrapper">
          <EmptyState handleAddRow={handleAddRow} setCurrentView={setCurrentView} />
        </div>
      ) : null}
      <div className="table-container">
        <table className="results-table">
          {renderTableHeader()}
          <tbody>
            {!showEmptyState && (
              <>
                {isAddingNewRow && renderInputRow()}
                {currentRows.map(renderEmployeeRow)}
              </>
            )}
          </tbody>
        </table>
      </div>
      <table className="fake-row-table">
        <tbody>
          <tr className="fake-row">
            <td colSpan="16">
              <button className="import-data-button" onClick={() => setCurrentView("upload")}>
                <img src={importDataIcon} alt="Import Data" className="icon" />
                Import data
              </button>
              <button className="new-employee-button" onClick={handleAddRow}>
                <img src={plusIcon} alt="New Employee" className="icon" />
                New employee
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="footer-section">
        <div className="total-members">
          Team Members: {rows.length}
        </div>
        <div className="pagination-controls">
          <button
            className="pagination-arrow"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <img src={paginationLeftIcon} alt="Previous" />
          </button>
          {renderPageNumbers()}
          <button
            className="pagination-arrow"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <img src={paginationRightIcon} alt="Next" />
          </button>
        </div>
      </div>
    {tooltip.show && (
        <DataSourceDisplay 
          source={tooltip.source} 
          position={tooltip.position}
        />
      )}
    {hoursWorkedTooltip.show && (
      <HoursWorkedTooltip 
        position={hoursWorkedTooltip.position}
      />
    )}
    </div>
  );
  
};

export default ResultsTable;
