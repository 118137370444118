import React from 'react';
import PropTypes from 'prop-types';
import './DocumentCard.css';
import csvIcon from '../../assets/csv-icon.png';
import xlsIcon from '../../assets/xls-icon.png';
import pdfIcon from '../../assets/pdf-icon.png';
import docxIcon from '../../assets/doc-icon.png';
import zipIcon from '../../assets/zip-icon.png';
import editIcon from '../../assets/edit-icon.png';
import deleteIcon from '../../assets/trash-icon.png';

const DocumentCard = ({ fileName, fileSize, fileType, progress, onEdit, onDelete, failedKeywordCheck }) => {
  const fileIcons = {
    csv: csvIcon,
    xls: xlsIcon,
    pdf: pdfIcon,
    docx: docxIcon,
    zip: zipIcon,
    xlsx: xlsIcon,
  };

  return (
    <div className={`document-card ${failedKeywordCheck ? 'failed-keyword-check' : ''}`}>
      <div className="document-card-icon-container">
        <img src={fileIcons[fileType]} alt={`${fileType} icon`} className="document-card-img" />
      </div>
      <div className="document-card-details">
        <div className="document-card-header">
          <div className="document-card-name">{fileName}</div>
          <div className="document-card-buttons">
            <button className="document-card-button" data-tooltip="Edit Name" onClick={onEdit}>
              <img src={editIcon} alt="Edit" />
            </button>
            <button className="document-card-button" data-tooltip="Delete" onClick={onDelete}>
              <img src={deleteIcon} alt="Delete" />
            </button>
          </div>
        </div>
        <div className="document-card-info">
          <div className="document-card-size">{fileSize}</div>
          <div className={`document-card-status ${failedKeywordCheck ? 'error-status' : ''}`}>
            {progress < 100 ? `${progress}%` : 
             failedKeywordCheck ? 'File not supported' : 'Completed'}
          </div>
        </div>
        {progress < 100 && (
          <div className="document-card-progress-container">
            <div className="document-card-progress" style={{ width: `${progress}%` }}></div>
          </div>
        )}
      </div>
    </div>
  );
};

DocumentCard.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.string.isRequired,
  fileType: PropTypes.oneOf(['csv', 'xls', 'pdf', 'docx', 'zip', 'xlsx']).isRequired,
  progress: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  failedKeywordCheck: PropTypes.bool
};

export default DocumentCard;