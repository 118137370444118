import React from 'react';
import boxIcon from './../../assets/box-icon.png';
import addManuallyIcon from './../../assets/add-manually-icon.png';
import uploadFileIcon from './../../assets/upload-file-icon.png';
import './EmptyState.css';

const EmptyState = ({ handleAddRow, setCurrentView }) => (
    <div className="empty-state">
      <img src={boxIcon} alt="Empty" className="empty-icon" />
      <p>You haven't submitted Employee and Owner/s Information yet.</p>
      <p>Let's start filling it up!</p>
      <div className="empty-buttons">
        <button className="button-add-manually-empty" onClick={handleAddRow}>
          <img src={addManuallyIcon} alt="Add Manually" />
          <span>ADD MANUALLY</span>
        </button>
        <button className="button-upload-file-empty" onClick={() => setCurrentView("upload")}>
          <img src={uploadFileIcon} alt="Upload File" />
          <span>UPLOAD FILE</span>
        </button>
      </div>
    </div>
  );
  

export default EmptyState;
