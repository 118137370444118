import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import TopNavBar from './components/Layout/TopNavBar';
import Footer from './components/Layout/Footer';
import MainContent from './components/MainContent';
import SigninCallback from './SigninCallback';
import PrivateRoute from './PrivateRoute';
import { isTokenValid, redirectToIdentityServer, logout } from './TokenUtils';
import { getProject, getCustomerNumber } from './api';
import './App.css';
import InactivityModal from './InactivityModal';
import MainLoading from './MainLoading';
import useIFinanceId from './useIFinanceId';

function App() {
  const [iFinanceId, setIFinanceId] = useIFinanceId();
  const [currentView, setCurrentView] = useState('results');
  const [currentProject, setCurrentProject] = useState('');
  const [censusYearData, setCensusYearData] = useState(null);
  const [user, setUser] = useState(null);
  const [customerNumber, setCustomerNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [showModal, setShowModal] = useState(false);
  const logoutTimeoutRef = useRef(null);
  const modalTimeoutRef = useRef(null);

  useEffect(() => {
    const initializeAuthentication = async () => {
      setIsLoading(true);

      const decodedTokenString = localStorage.getItem('decoded_token');
      const decodedToken = decodedTokenString ? JSON.parse(decodedTokenString) : null;
      const idToken = localStorage.getItem('id_token');
      if (decodedToken && isTokenValid(decodedToken) && iFinanceId) {
        setUser({ idToken, decodedToken });

        try {
          if(!iFinanceId){
            return;
          }
          const customerNumber = await getCustomerNumber(iFinanceId);
          if(customerNumber){
            setCustomerNumber(customerNumber);
            const { id: projectId, censusYearData } = await getProject(customerNumber, iFinanceId);
            setCurrentProject(projectId);
            setCensusYearData(censusYearData);
          }
        } catch (error) {
          console.error('Error getting project:', error);
        }
      } else if (!decodedToken) {
        redirectToIdentityServer();
      } else if(!iFinanceId) {
        alert("No IFinanceID found.");
        handleLogout();
      }
    };
  
    initializeAuthentication();
    console.log("Version 1.1.47");
  }, [iFinanceId]);


  const handleActivity = () => {
    setLastActivity(Date.now());
    setShowModal(false);
  };

  const handleExtendSession = () => {
    localStorage.removeItem('id_token');
    localStorage.removeItem('decoded_token');
    redirectToIdentityServer();
  };

  const handleLogout = () => {
    const idToken = localStorage.getItem('id_token');
    if (idToken) {
      logout(idToken);
    }
  };

  useEffect(() => {
    const events = ['click', 'keydown', 'scroll'];
    events.forEach(event => window.addEventListener(event, handleActivity));

    return () => {
      events.forEach(event => window.removeEventListener(event, handleActivity));
    };
  }, []);

  useEffect(() => {
    if (logoutTimeoutRef.current) {
      clearTimeout(logoutTimeoutRef.current);
    }
    if (modalTimeoutRef.current) {
      clearTimeout(modalTimeoutRef.current);
    }

    modalTimeoutRef.current = setTimeout(() => {
      setShowModal(true);
    }, 28 * 60 * 1000);

    logoutTimeoutRef.current = setTimeout(() => {
      handleLogout();
    }, 30 * 60 * 1000);

    return () => {
      clearTimeout(logoutTimeoutRef.current);
      clearTimeout(modalTimeoutRef.current);
    };
  }, [lastActivity]);

  return (
    <div className="App">
      <TopNavBar user={user} logout={logout} />
      {isLoading && <MainLoading />}
      <Routes>
        <Route path="/signin-oidc" element={<SigninCallback setUser={setUser} setIsLoading={setIsLoading} setIFinanceId={setIFinanceId} />} />
        <Route
          path="/"
          element={
            <PrivateRoute 
              element={
                user ? (
                  <MainContent 
                    currentView={currentView} 
                    setCurrentView={setCurrentView} 
                    currentProject={currentProject} 
                    setCurrentProject={setCurrentProject}
                    customerNumber={customerNumber}
                    setIsLoading={setIsLoading}
                    iFinanceId={iFinanceId}
                    censusYearData={censusYearData}
                  />
                ) : (
                  <Navigate to="/signin-oidc" />
                )
              } 
            />
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
      {showModal && <InactivityModal onExtendSession={handleExtendSession} />}
    </div>
  );
}

export default App;
