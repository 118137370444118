import React, { useState } from 'react';
import './BulkConflictModal.css';
import closeIcon from './../../assets/close-icon.png';

const BulkConflictModal = ({ show, onClose, sources, onApplyChange, bulkConflictField }) => {
  const [selectedSource, setSelectedSource] = useState({});

  if (!show) {
    return null;
  }

  const fieldDisplayNames = {
    PriorYearEmployee: 'Prior Year Employee',
    FirstName: 'First Name',
    LastName: 'Last Name',
    MiddleName: 'Middle Name',
    OwnershipPercent: 'Ownership Percent',
    SSN: 'SSN',
    DateOfBirth: 'Date of Birth',
    DateOfHire: 'Date of Hire',
    DateOfReHire: 'Date of Rehire',
    DateOfTermination: 'Date of Termination',
    Status: 'Status',
    GrossCompensation: 'Gross Compensation',
    GrossCompensationNote: 'Gross Compensation Note',
    HoursWorked: 'Hours Worked',
    HoursWorkedNote: 'Hours Worked Note',
    DeferALS: 'Defer ALS',
    ParticipantRoth: 'Participant Roth',
    CompanyMatch: 'Company Match',
    CompanyProfitSharing: 'Company Profit Sharing',
    AnnualLoanPayment: 'Annual Loan Payment',
    RollOver: 'Rollover',
  };

  const getFieldDisplayName = (field) => {
    return fieldDisplayNames[field] || field;
  };

  const handleApplyChange = () => {
    if (selectedSource) {
      onApplyChange(selectedSource); // Pass the selected source object
      onClose();
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content bulk-conflict-modal">
        <div className="modal-header">
          <h2>Choose the Correct Source For All {getFieldDisplayName(bulkConflictField)}</h2>
          <img
            src={closeIcon}
            alt="Close"
            className="modal-close-icon"
            onClick={onClose}
          />
        </div>

        <hr className="modal-header-divider" />

        <p className="discrepancy-text">
          We found discrepancies in the values of the documents you uploaded. Select the source below to apply to all conflicts.
        </p>

        <table className="modal-table">
          <thead>
            <tr>
              <th>SOURCE</th>
              <th>PREVIEW</th>
            </tr>
          </thead>
          <tbody>
            {sources.map((sourceObj, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="radio"
                    name="sourceSelection"
                    checked={selectedSource === sourceObj.Source}
                    onChange={() => setSelectedSource(sourceObj.Source)}
                  />
                  {sourceObj.Source}
                </td>
                <td>{sourceObj.Value}</td>
              </tr>
            ))}
          </tbody>
        </table>


        <div className="modal-button-container">
          <button className="modal-apply-button" onClick={handleApplyChange}>
            APPLY TO ALL
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkConflictModal;
