import React from 'react';
import './Sidebar.css';
import pdfIcon from '../../assets/pdf-icon.png';
import csvIcon from '../../assets/csv-icon.png';
import xlsIcon from '../../assets/xls-icon.png';
import docIcon from '../../assets/doc-icon.png';
import defaultIcon from '../../assets/default-icon.png';

function Sidebar({ sources, setCurrentView }) { 
  const getIconForFileType = (fileName) => {
    if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
      return xlsIcon;
    } else if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) {
      return docIcon;
    } else if (fileName.endsWith('.pdf')) {
      return pdfIcon;
    } else if (fileName.endsWith('.csv')) {
      return csvIcon;
    } else {
      return defaultIcon;
    }
  };

  return (
    <div className="sidebar">
      <h2>Uploaded Documents</h2>
      <ul className="document-list">
        {sources.map((doc, index) => (
          <li key={index} className="document-item">
            <img 
              src={getIconForFileType(doc.title ? doc.title : doc)} 
              alt="Document" 
              className="document-icon" 
            />
            <span>{doc.title ? doc.title : doc}</span>
            <span className="tooltip-text">{doc.title ? doc.title : doc}</span>
          </li>
        ))}
      </ul>
      <button className="add-document-button" onClick={() => setCurrentView("upload")}>
        Add another document
      </button>
    </div>
  );
}

export default Sidebar;
