import React from 'react';
import './MainLoading.css';

const MainLoading = () => {
  return (
    <div className="main-loading-overlay">
      <div className="main-loading-spinner"></div>
    </div>
  );
};

export default MainLoading;
