import React from 'react';
import UploadFileContent from './UploadFilePage/UploadFileContent';
import ResultsContent from './ResultsPage/ResultsContent';
import IntroductionContent from './Introduction/IntroductionContent';
import './MainContent.css';

function MainContent({ currentView, setCurrentView, currentProject, setCurrentProject, customerNumber, setIsLoading, iFinanceId, censusYearData}) {
  return (
    <main className="main-content">
      {currentView === 'upload' && (
        <UploadFileContent
          setCurrentView={setCurrentView}
          currentView={currentView}
          censusYearData={censusYearData}
          customerNumber={customerNumber}
        />
      )}
      {currentView === 'results' && (
        <ResultsContent 
          setCurrentView={setCurrentView} 
          currentProject={currentProject} 
          currentView={currentView} 
          customerNumber={customerNumber} 
          setIsLoading={setIsLoading}
          iFinanceId={iFinanceId} 
          censusYearData={censusYearData} 
        />
      )}
      {currentView === 'introduction' && (
        <IntroductionContent setCurrentView={setCurrentView} />
      )}
    </main>
  );
}

export default MainContent;