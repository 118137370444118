import React from 'react';

const HoursWorkedTooltip = ({ position }) => {
  return (
    <div 
      className="hours-worked-tooltip"
      style={{
        position: 'fixed',
        top: `${position.y}px`,
        left: `${position.x}px`,
        zIndex: 10000,
        background: '#555',
        color: 'white',
        padding: '10px',
        borderRadius: '6px',
        fontSize: '14px',
        maxWidth: '350px',
        wordWrap: 'break-word',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
      }}
    >
      <div className="tooltip-title" style={{ fontWeight: 'bold', marginBottom: '5px' }}>
        Exclusive Benefit Rule / Bona Fide Employee
      </div>
      <div>
        The person who rolls their retirement funds into the ROBS structure must be a bona fide employee of the business they've funded. The rule is somewhat vague, but to be safe, Guidant suggests the owner work at least 500 hours per year.
      </div>
    </div>
  );
};

export default HoursWorkedTooltip;