import React from 'react';
import styles from './ExpectedInformation.module.css';
import personalInformationIcon from '../../assets/personal-information-icon.png';
import terminationIcon from '../../assets/termination-icon.png';
import payrollIcon from '../../assets/payroll-icon.png';
import otherPayrollIcon from '../../assets/other-payroll-icon.png';
import infoIcon from '../../assets/black-info-icon.png';

const InfoCategory = ({ icon, title, items, iconClass }) => (
  <div className={styles.infoCategory}>
    <img src={icon} alt={`${title} icon`} className={`${styles.categoryIcon} ${styles[iconClass]}`} />
    <div className={styles.categoryContent}>
      <strong>{title}</strong>
      <div className={styles.infoItems}>
        {items.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>
    </div>
  </div>
);

function ExpectedInformation() {
  return (
    <div className={styles.expectedInformation}>
      <div className={styles.header}>
        <h3>Expected Information</h3>
        {/* <img src={infoIcon} alt="Info icon" className={styles.infoIcon} /> */}
      </div>
      <div className={styles.infoSection}>
        <InfoCategory
          icon={personalInformationIcon}
          title="Personal information"
          items={['First Name', 'Last Name', 'Social Security Number', 'Birth Date', 'Hire Date']}
          iconClass="personalInformationIcon"
        />
        <InfoCategory
          icon={terminationIcon}
          title="Termination (if applies)"
          items={['Status', 'Termination Date']}
          iconClass="terminationIcon"
        />
        <InfoCategory
          icon={payrollIcon}
          title="Payroll"
          items={['Hours Worked', 'Gross Compensation']}
          iconClass="payrollIcon"
        />
        <InfoCategory
          icon={otherPayrollIcon}
          title="Other Payroll"
          items={[
            'Participant Deferrals',
            'Participant Roth',
            'Company Match',
            'Company Profit',
            'Sharing',
            'Qualified Rollover',
            'Loan Payment',
          ]}
          iconClass="otherPayrollIcon"
        />
      </div>
    </div>
  );
}

export default ExpectedInformation;
