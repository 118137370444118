import React from 'react';
import AlertTag from './AlertTag';
import './AlertsList.css';

const AlertsList = ({ alerts, alertFilters, handleAlertClick }) => {
  const showErrors = alerts.errors > 0;
  const showWarnings = alerts.warnings > 0;
  const showInvalids = alerts.invalids > 0;
  const showReviewFor = showErrors || showWarnings || showInvalids;

  if (!showReviewFor) {
    return null;
  }

  return (
    <div className="alerts-wrapper">
      {showReviewFor && <span className="review-label">Review for:</span>}
      <div className="alerts-container">
        {showErrors && (
          <AlertTag
            label="Errors"
            count={alerts.errors}
            onClick={() => handleAlertClick('Error')}
            active={alertFilters.includes('Error')}
          />
        )}
        {showWarnings && (
          <AlertTag
            label="Warnings"
            count={alerts.warnings}
            onClick={() => handleAlertClick('Warning')}
            active={alertFilters.includes('Warning')}
          />
        )}
        {showInvalids && (
          <AlertTag
            label="Required Fields"
            count={alerts.invalids}
            onClick={() => handleAlertClick('Invalid')}
            active={alertFilters.includes('Invalid')}
          />
        )}
      </div>
    </div>
  );
};

export default AlertsList;