import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode'; // Import jwtDecode
import { isTokenValid, redirectToIdentityServer } from './TokenUtils';

const PrivateRoute = ({ element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const idToken = localStorage.getItem('id_token');
    if (idToken) {
      const decodedIdToken = jwtDecode(idToken); // Decode the token
      if (isTokenValid(decodedIdToken)) { // Validate the decoded token
        setIsAuthenticated(true);
      } else {
        console.log('User not authenticated, redirecting to identity server');
        redirectToIdentityServer();
      }
    } else {
      console.log('No ID token found, redirecting to identity server');
      redirectToIdentityServer();
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return null;
  }

  return element;
};

export default PrivateRoute;
