import React from 'react';
import searchIcon from './../../assets/search-icon.png';
import addManuallyIcon from './../../assets/add-manually-icon.png';
import uploadFileIcon from './../../assets/upload-file-icon.png';
import './Toolbar.css';

function Toolbar({ activeTab, setActiveTab, handleAddRow, setCurrentView, searchTerm, setSearchTerm, isOldData }) {
  return (
    <div className="toolbar">
      {!isOldData && (
        <div className="tabs">
          {['viewAll', 'newEmployees', 'previousYear'].map((tab) => (
            <button
              key={tab}
              className={`tab ${activeTab === tab ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab === 'viewAll'
                ? 'View All'
                : tab === 'newEmployees'
                ? 'Current Year Hires'
                : 'Previous Year Hires'}
            </button>
          ))}
        </div>
      )}
      <div className={`search-bar ${isOldData ? 'search-bar--no-margin' : ''}`}>
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <img src={searchIcon} alt="Search" className="search-icon" />
      </div>
      {/* {!isOldData && (
        <div className="buttons">
          <button className="button-add-manually" onClick={handleAddRow}>
            <img src={addManuallyIcon} alt="Add Manually" />
            ADD MANUALLY
          </button>
          <button className="button-upload-file" onClick={() => setCurrentView('upload')}>
            <img src={uploadFileIcon} alt="Upload File" />
            UPLOAD FILE
          </button>
        </div>
      )} */}
    </div>
  );
}

export default Toolbar;