import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { isTokenValid, redirectToIdentityServer } from './TokenUtils';

function SigninCallback({ setUser, setIsLoading, setIFinanceId }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const processSignin = async () => {
      // setIsLoading(true);

      try {
        const params = new URLSearchParams(location.hash.slice(1));
        const idToken = params.get('id_token');
        if (idToken) {
          const decodedIdToken = jwtDecode(idToken);
          if (isTokenValid(decodedIdToken)) {
            const storedNonce = sessionStorage.getItem('auth_nonce');
            if (!storedNonce || decodedIdToken.nonce !== storedNonce) {
              throw new Error('Nonce validation failed');
            }
            sessionStorage.removeItem('auth_nonce');
            setUser({ idToken, decodedIdToken });
            localStorage.setItem('id_token', idToken);
            localStorage.setItem('decoded_token', JSON.stringify(decodedIdToken));
            // setIsLoading(false);
            window.history.replaceState({}, document.title, window.location.pathname);

            const storedId = localStorage.getItem('iFinanceId');
            if (storedId) {
              setIFinanceId(storedId);
            }

            navigate('/'); // Redirect to MainContent
            return;
          } else {
            throw new Error('Token validation failed');
          }
        } else {
          throw new Error('No ID token found in the callback');
        }
      } catch (error) {
        console.error('Error processing ID token:', error);
        redirectToIdentityServer(); 
      }

      // setIsLoading(false);
    };

    processSignin();
  }, [location, navigate, setUser, setIsLoading, setIFinanceId]);

  return null;
}

export default SigninCallback;
