import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useIFinanceId = () => {
  const location = useLocation();
  const [iFinanceId, setIFinanceId] = useState(() => localStorage.getItem('iFinanceId'));

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const idFromPath = pathParts.length > 1 && pathParts[1] !== 'signin-oidc' ? pathParts[1] : null;
    
    if (idFromPath) {
      localStorage.setItem('iFinanceId', idFromPath);
      setIFinanceId(idFromPath);
    } else {
      const storedId = localStorage.getItem('iFinanceId');
      if (storedId) {
        setIFinanceId(storedId);
      }
    }
  }, [location]);

  return [iFinanceId, setIFinanceId];
};

export default useIFinanceId;
