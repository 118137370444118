import React from 'react';
import './IntroductionContent.css';
import introImage from '../../assets/intro-image.png'; // Ensure the correct path to your image
import arrowIcon from '../../assets/arrow-right.png'; // Ensure the correct path to your image

function IntroductionContent({ setCurrentView }) {
  return (
    <div className="introduction-container">
      <div className="introduction-text">
        <div className="breadcrumb">Census / Plan Year: Jul 1, 2022 - Jun 30, 2023</div>
        <h1 className="introduction-main-heading">Employee and Owner/s Census for</h1>
        <h1 className="introduction-main-subheading">Mellow Mushroom</h1>
        <h2 className="introduction-subheading">Process Overview</h2>
        <p className="introduction-paragraph">
          Hello Ashwin, Please provide employee and owner/s details: names, SSNs, hours, compensation, DOBs, hire, and termination dates. Guidant utilizes this data for plan eligibility, participation, and contributions. Let's automate with Guidant Payroll next year. Learn more <a href="#" className="introduction-link">here</a>.
        </p>
        <p className="introduction-paragraph">
          After completing the Employee Census, upload supporting documents. Guidant will compile annual reports for filing with the Department of Labor, ensuring 401(k) plan compliance.
        </p>
        <h2 className="introduction-subheading">Gather the following items</h2>
        <ul className="introduction-list">
          <li>Employee Records</li>
          <li>Payroll Records</li>
          <li>Employee Investment Management Statements (if applicable)</li>
          <li>401(k) Bank Statements</li>
        </ul>
        <button className="introduction-button" onClick={() => setCurrentView('results')}>
          LETS GET STARTED!
          <img src={arrowIcon} alt="Arrow Right" className="arrow-icon" />
        </button>
      </div>
      <div className="introduction-image-container">
        <img src={introImage} alt="Introduction" className="introduction-image"/>
      </div>
    </div>
  );
}

export default IntroductionContent;
