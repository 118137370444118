import React from 'react';
import './DeleteConfirmationModal.css';
import deleteWarningIcon from './../../assets/delete-warning-icon.png';

const DeleteConfirmationModal = ({ show, onClose, handleConfirmDeleteRow, handleConfirmDeleteProject, deleteMessage, isDeletingAll }) => {
  if (!show) {
    return null;
  }

  const handleConfirmDelete = () => {
    if (isDeletingAll) {
      handleConfirmDeleteProject();
    } else {
      handleConfirmDeleteRow();
    }
  };

  return (
    <div className="delete-modal-overlay">
      <div className="delete-modal-content">
        <div className="delete-modal-header">
          <h2>Confirm Delete</h2>
          <span className="close-icon" onClick={onClose}>×</span>
        </div>
        <div className="delete-modal-body">
          <div className="delete-modal-icon">
            <img src={deleteWarningIcon} alt="Warning Icon" />
          </div>
          <div className="delete-modal-message">
            <p className="action-message">This action cannot be undone.</p>
            <p className="delete-message">{deleteMessage}</p>
          </div>
        </div>
        <div className="delete-modal-divider"></div>
        <div className="delete-modal-actions centered-buttons">
          <button className="delete-modal-close-button" onClick={onClose}>NO, REVIEW AGAIN</button>
          <button className="delete-modal-delete-button" onClick={handleConfirmDelete}>YES, DELETE</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
