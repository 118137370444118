import React from 'react';
import './MainContentHeader.css';

function MainContentHeader({ start_date, end_date, subtitle, description, currentView }) {
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    return new Date(dateString + 'T00:00:00').toLocaleDateString(undefined, options);
  };

  const formatTitle = () => {
    if (!start_date || !end_date) return '';
    const formattedStart = formatDate(start_date);
    const formattedEnd = formatDate(end_date);
    const planYearTitle = `Plan Year: ${formattedStart} - ${formattedEnd}`;
    
    if (currentView === 'upload') {
      return `Census&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Upload&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;${planYearTitle}`;
    }

    return `Census&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;${planYearTitle}`;
  };

  const title = formatTitle();

  return (
    <div className="main-content-header">
      {title && <h1 className="plan-year" dangerouslySetInnerHTML={{ __html: title }} />}
      <h2 className="main-header">{subtitle}</h2>
      <p className="description">{description}</p>
    </div>
  );
}

export default MainContentHeader;
