import React, { useState, useEffect, useRef } from 'react';
import './TopNavBar.css';
import logo from '../../assets/guidant-logo.svg';
import signoutIcon from '../../assets/signout-icon.png';

function TopNavBar({logout}) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const handleSignOut = () => {
    logout();
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <header className="top-nav-bar">
      <div className="logo">
        <img src={logo} alt="Guidant Logo" />
      </div>
      <div className="nav-links">
        <span>425-289-3200</span>
        <a 
          href="#!" 
          onClick={() => setDropdownVisible(!dropdownVisible)} 
          className="account-link"
        >
          My Account
        </a>
        {dropdownVisible && (
          <div ref={dropdownRef} className="dropdown-menu">
            <div className="signout-button" onClick={handleSignOut}>
              <img src={signoutIcon} alt="Sign Out Icon" className="signout-icon" />
              <span>Sign Out</span>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default TopNavBar;
