import React from 'react';

const DataSourceDisplay = ({ source, position }) => {
  if (!position) return null;

  return (
    <div 
      className="data-source-floating-display"
      style={{
        position: 'fixed',
        top: `${position.y}px`,
        left: `${position.x}px`,
      }}
    >
      Source: {source || 'N/A'}
    </div>
  );
};

export default DataSourceDisplay;