import React from 'react';
import './ValidationErrorModal.css';
import warningIcon from './../../assets/red-warning-icon.png';

const ValidationErrorModal = ({ show, onClose, errors }) => {
  if (!show) return null;

  return (
    <div className="validation-modal-overlay">
      <div className="validation-modal-content">
        <div className="validation-modal-header">
          <h2>Validation Errors</h2>
          <span className="close-icon" onClick={onClose}>×</span>
        </div>
        <div className="validation-modal-message">
          <ul>
            {errors.map((error, index) => (
              <li key={index}>
                <img src={warningIcon} alt="Warning" className="warning-icon" />
                {error}
              </li>
            ))}
          </ul>
        </div>
        <div className="validation-modal-divider"></div>
        <div className="validation-modal-actions">
          <button className="validation-modal-close-button" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ValidationErrorModal;
