import React from 'react';
import './ProcessUploadModal.css';

function ProcessUploadModal({ isOpen }) {
  if (!isOpen) return null;

  return (
    <div className="process-upload-modal-overlay">
      <div className="process-upload-modal-content">
        <div className="process-upload-modal-header">
          <h2>Processing Upload</h2>
        </div>
        <div className="process-upload-modal-body">
          <div className="process-upload-radial-progress-container">
            <div className="process-upload-radial-progress"></div>
          </div>
          <p>Please wait while we upload and process your files.</p>
        </div>
      </div>
    </div>
  );
}

export default ProcessUploadModal;
