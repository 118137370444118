import React from 'react';
import './InactivityModal.css';

const InactivityModal = ({ onExtendSession }) => {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>SESSION TIMEOUT</h2>
        <p>We noticed you haven't made any changes in a while. You will be logged out in 2 minutes.</p>
        <button onClick={onExtendSession}>EXTEND MY SESSION</button>
      </div>
    </div>
  );
};

export default InactivityModal;
