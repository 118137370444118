import { useState, useEffect } from 'react';
import { getProcessingStatus } from '../../api';

const useProcessingStatus = (currentProject, fetchResults) => {
  const [processingStatus, setProcessingStatus] = useState({
    isProcessing: false,
    totalDocuments: 0,
    documentsProcessed: 0,
  });
  const [showProcessingModal, setShowProcessingModal] = useState(false);

  const fetchProcessingStatus = async () => {
    try {
      if (!currentProject) {
        return false;
      }
      const response = await getProcessingStatus(currentProject);
      const isProcessing = response.status;

      setProcessingStatus({
        isProcessing,
        totalDocuments: response.totalDocuments,
        documentsProcessed: response.documentsProcessed,
      });

      if (!isProcessing) {
        setShowProcessingModal(false);
        await fetchResults();
      }

      return isProcessing;
    } catch (error) {
      console.error("Error fetching processing status:", error);
      setProcessingStatus(prevStatus => ({
        ...prevStatus,
        isProcessing: false,
      }));
      return false;
    }
  };

  useEffect(() => {
    const initialFetch = async () => {
      const isProcessing = await fetchProcessingStatus();
      if (isProcessing) {
        setShowProcessingModal(true);
        const intervalId = setInterval(async () => {
          const isProcessing = await fetchProcessingStatus();
          if (!isProcessing) {
            clearInterval(intervalId);
          }
        }, 15000); // 15 seconds interval
        return () => clearInterval(intervalId); // Cleanup interval on unmount
      }
    };

    initialFetch(); // Initial fetch and modal check
  }, [currentProject]);

  const checkProcessingStatusAndShowModal = () => {
    setShowProcessingModal(processingStatus.isProcessing);
    return processingStatus.isProcessing;
  };

  return { processingStatus, showProcessingModal, setShowProcessingModal, checkProcessingStatusAndShowModal };
};

export default useProcessingStatus;
