import React from 'react';
import './ProcessingModal.css';
import rightStars from './../../assets/loading-stars-right.png';
import leftStars from './../../assets/loading-stars-left.png';

const ProcessingModal = ({ show, onClose, processingStatus }) => {
  if (!show) {
    return null;
  }

  let { totalDocuments, documentsProcessed } = processingStatus;

  totalDocuments = Number(totalDocuments);
  documentsProcessed = Number(documentsProcessed);

  const timeEstimationInSeconds = (totalDocuments - documentsProcessed) * 10;

  const timeEstimationInMinutes = (timeEstimationInSeconds / 60).toFixed(2);

  return (
    <div className="processing-modal-overlay">
      <div className="processing-modal">
        <div className="processing-modal-header">
          <h2>Processing Information</h2>
          <p>Please wait while we consolidate all data into a unified datasheet.</p>
          <p>Estimated Time: {timeEstimationInMinutes} minutes.</p>
        </div>
        <div className="processing-modal-body">
          <div className="radial-progress-container">
            <img src={leftStars} alt="Left Stars" className="left-stars" />
            <div className="radial-progress"></div>
            <img src={rightStars} alt="Right Stars" className="right-stars" />
          </div>
          <div className="checklist">
            <div className="checklist-item">
              <span className="info-icon"><i className="fas fa-info-circle"></i></span>
              <p>Gathering information from various sources into a single comprehensive view.</p>
            </div>
            <div className="checklist-item">
              <span className="info-icon"><i className="fas fa-info-circle"></i></span>
              <p>Categorizing and arranging documents and data for clarity and ease of management.</p>
            </div>
            <div className="checklist-item">
              <span className="info-icon"><i className="fas fa-info-circle"></i></span>
              <p>Analyzing combined information to offer valuable insights and summaries for informed decision-making.</p>
            </div>
          </div>
          <button className="processing-close-button" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
  
  
};

export default ProcessingModal;
